export const loginApi = 'https://sisporlaka-backend.iterahero.com/login'
export const dashboardInfoApi = 'https://sisporlaka-backend.iterahero.com/count'
export const countKecamatan = 'https://sisporlaka-backend.iterahero.com/countkecamatan'
export const getKecamatanApi = 'https://sisporlaka-backend.iterahero.com/kecamatan'
export const getLaporan = 'https://sisporlaka-backend.iterahero.com/laporan?'
export const getLaporanToCount = 'https://sisporlaka-backend.iterahero.com/laporanall'
export const getLaporanToCountUnvalidatedLaporan = 'https://sisporlaka-backend.iterahero.com/countkecamatanunvalidated'
export const getLaporanValidation = 'https://sisporlaka-backend.iterahero.com/laporanvalidate?'
export const updateStatusApi = 'https://sisporlaka-backend.iterahero.com/laporanstatus/'
export const deleteLaporanApi = 'https://sisporlaka-backend.iterahero.com/laporan/'
export const postLaporanApi = 'https://sisporlaka-backend.iterahero.com/laporan'
export const getAllKecamatan = 'https://sisporlaka-backend.iterahero.com/kecamatan'
export const getIdUserByTokenApi = 'https://sisporlaka-backend.iterahero.com/token'
export const createDetailLaporanPolisi = 'https://sisporlaka-backend.iterahero.com/laporandetail'
export const getAllKategori = 'https://sisporlaka-backend.iterahero.com/kategori'
export const getAllLuka = 'https://sisporlaka-backend.iterahero.com/luka'
export const getAllKorban = 'https://sisporlaka-backend.iterahero.com/identitaskorban'
export const getAllSkala = 'https://sisporlaka-backend.iterahero.com/skala'
export const getAllICD10 = 'https://sisporlaka-backend.iterahero.com/icd-10'
export const getIdentitasKorbanById = 'https://sisporlaka-backend.iterahero.com/identitaskorban/'
export const getAllSantunan = 'https://sisporlaka-backend.iterahero.com/santunan'
export const postKorban = 'https://sisporlaka-backend.iterahero.com/korban'
export const deleteKorbanById = 'https://sisporlaka-backend.iterahero.com/korban/'
export const postSantunan = 'https://sisporlaka-backend.iterahero.com/identitassantunan'
export const getAllIdentitasKorban = 'https://sisporlaka-backend.iterahero.com/santunankorban/'
export const deleteSantunanById = 'https://sisporlaka-backend.iterahero.com/identitassantunan/'
export const updateKorbanById = 'https://sisporlaka-backend.iterahero.com/korban/'
export const updateSantunanById = 'https://sisporlaka-backend.iterahero.com/santunan/'
export const getPengemudi = 'https://sisporlaka-backend.iterahero.com/pengemudi/'
export const updatePengemudiById = 'https://sisporlaka-backend.iterahero.com/pengemudi/'
export const deletePengemudiById = 'https://sisporlaka-backend.iterahero.com/pengemudi/'
export const updateLaporan =  'https://sisporlaka-backend.iterahero.com/laporan/'
export const getLaporanById = 'https://sisporlaka-backend.iterahero.com/laporan/'
export const getJumlahKorban = 'https://sisporlaka-backend.iterahero.com/jumlahkorban/'
export const getLaporanDownload = 'https://sisporlaka-backend.iterahero.com/download?'
export const getGrafik = 'https://sisporlaka-backend.iterahero.com/grafik?'
export const selectDataGrafik = 'https://sisporlaka-backend.iterahero.com/databulan'
export const listData = 'https://sisporlaka-backend.iterahero.com/list'
export const getPublicLaporan = 'https://sisporlaka-backend.iterahero.com/publicreport'
export const getPublicReportUnlimited = 'https://sisporlaka-backend.iterahero.com/publicreportunlimited'
export const logoutUser = 'https://sisporlaka-backend.iterahero.com/logout'