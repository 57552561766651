import React from 'react'
import { TabTitle } from '../Utility/utility';
const Notfound = () =>{
  TabTitle("Halaman Tidak Ditemukan - Sisporlaka");
  return (
    <div>
      404 not found
    </div>
  )
}

export default Notfound